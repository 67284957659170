import axios from 'axios';
import config from '../config.json';
import utilities from '../utility/utilities';

const BaseUrl = config.event_url;


export enum event_name_enum {
    details_view = 'details_view',
    details_session = 'details_session',
    learn_view = 'learn_view',
    learn_session = 'learn_session',
    play = 'play',
    complete = 'complete',
    uncomplete = 'uncomplete',
}
export enum source_enum {
    courses = 'courses',
}
export enum source_type_enum {
    courses = 'courses',
}


export enum sub_source_enum {
    chapter = 'chapter',
}

export enum sub_source_name_enum {
    chapter_name = 'chapter_name',
}
export enum platform_enum {
    website = 'website',
}
export enum unit_enum {
    seconds = 'seconds',
    bool = 'bool',
}
export enum os_enum {
    ios = 'ios',
    android = 'android',
    windows = 'windows',
    linux = 'linux',
}



interface ICreateEventBody {
    event?: event_name_enum
    source?: source_enum
    source_type?: source_type_enum
    source_id?: string
    source_name?: string
    sub_source?: sub_source_enum
    sub_source_id?: string
    value?: number | boolean
    activity_start?: Date
    activity_end?: Date
    unit?: unit_enum
    meta?: {
        platform: platform_enum
        os: string
        version: string
        click_source: string | null
        click_action: string | null
    }
    session_id?: string
    remark?: any
}

export const createEvent = async (data: ICreateEventBody) => {
    try {
        const token = await utilities.getJwtToken()
        const response = await axios.post(`${BaseUrl}`, data, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        console.error(error);
        return error.response;
    }
}




