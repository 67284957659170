import './App.css';
import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import CourseDetails from './pages/courseDetails/courseDetails';
import MyLearning from './pages/myLearning/myLearning';
import Landing from './pages/landing/landing';
import Signin from './pages/auth/signin';
import Learn from './pages/learn/learn';
import Sections from './pages/learn/sections';
import { useEffect, useState } from 'react';
import api from './services/api';
import Admin from './pages/admin/admin';
import AllCourses from './pages/admin/courses';
import Authors from './pages/admin/authors';
import CreateCourse from './pages/admin/createCourse/createCourse';
import CreateAuthor from './pages/admin/createAuthor/createAuthor';
import EditCourse from './pages/admin/editCourse/editCourse';
import EditAuthor from './pages/admin/editAuthor/editAuthor';
import "./assets/css/main.css"
import CryptoJS from 'crypto-js';
import config from "./config.json"
import utilities from "./utility/utilities";
import NotFound from "./pages/utility/notFound";
import Categories from "./pages/admin/categories";
import account from "./utility/appWriteConfig";
import { getCookieV2, storeCookieV2 } from "./utility/cookieUtility";
import CreateChapter from "./pages/admin/chapterCreate/createCourse";
import K12 from './pages/k12';
import Public from './components/public/public';


function App() {


    const { search } = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isCalled, setIsCalled] = useState(false)
    const [roleId, setRoleId] = useState(0)
    const [currentUser, setCurrentUser] = useState({})
    const [called, setCalled] = useState(true)

    const isUser = async () => {
        const token = utilities.getCookie('token')

        try {
            if (!token) {
                throw new Error('Token not present');
            }
            const response = await api.isUser();

            if (response && response.data && response.data.data && response.data.data.role_id) {
                setRoleId(response.data.data.role_id)
                setIsAuthenticated(true)
                setIsCalled(true)
                setCurrentUser(response.data.data)
                return;
            }

            if (!response && !response.data && !response.data.data && !response.data.data.id) {
                throw new Error('User not present');
            }
        } catch (e) {
            setIsAuthenticated(false)
            setIsCalled(true)
            console.error({ message: "Error in isUser", error: e })
        }

    }

    const initFunction = async () => {
        try {
            const searchParams = new URLSearchParams(search);
            const redirect_link_url = searchParams.get('redirect_url');
            const redirect_link = decodeURIComponent(redirect_link_url)
            if (!redirect_link || redirect_link === 'null') {
                return;
            }
            const decrypted = CryptoJS.AES.decrypt(redirect_link, config.CRYPTO_KEY);
            const token = decrypted.toString(CryptoJS.enc.Utf8);

            const createSecret = await api.createSecret(token);

            if (createSecret && createSecret.data && createSecret.data.data && createSecret.data.data && createSecret.data.data.secret && createSecret.data.data.zl_uid) {
                const secret = createSecret.data.data.secret;
                const zl_uid = createSecret.data.data.zl_uid;

                const isSessionPresent = await account.get();

                if (!isSessionPresent) {
                    await account.createSession(zl_uid, secret);
                }

                const user = await account.createJWT();

                if (user && user.jwt) {
                    storeCookieV2("token", user.jwt, 840);
                }


            }

            setCalled(!called)
        } catch (e) {
            console.error({ message: "Error in initFunction", error: e })
        }
    }

    const encryptToken = () => {
        const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiI2NjQ1YzRlZjViMDE1OWYzNDgxNyIsInNlc3Npb25JZCI6IjY2NTFjODBhZWY4MjhkMzNkMWI0IiwiZXhwIjoxNzE2NjM5MTIxfQ.8Jdaj6It9ZSD_1uCne_f0q5EMXw_ePq4iFTd2XDQFq8"

        const encrypted = CryptoJS.AES.encrypt(token, config.CRYPTO_KEY).toString();
        const encode = encodeURIComponent(encrypted);
        console.error("encode: ", encode)
    };


    useEffect(() => {
        // encryptToken()
        initFunction()
    }, [])

    useEffect(() => {
        isUser()
    }, [called])

    const redirectUser = () => {
        const currentUrl = window.location.href;
        if (currentUrl === config.domain_name + '/#/' + 'course/info/options-glossary-aseem-singhal') {
            return <Navigate to="/public/options-glossary-aseem-singhal" />; // Add your redirect destination
        }
        if (currentUrl === config.domain_name + '/#/' + 'course/info/trading-glossary-aseem-singhal') {
            return <Navigate to="/public/trading-glossary-aseem-singhal" />; // Add your redirect destination
        }
    };


    if (isCalled) {
        return (
            <>
                {redirectUser() /* Call the redirect function */}
                <Routes>
                    <Route path="/public/:name" element={<Public />} />
                    <Route path='/' element={<Landing isUser={isUser} currentUser={currentUser ? currentUser : {}}
                        isAuthenticated={isAuthenticated}
                        isAdmin={roleId === 3 ? true : false} />} />
                    <Route path="/auth/signin" element={<Signin isUser={isUser} setIsAuthenticated={setIsAuthenticated} />} />
                    <Route path="/course/info/:courseId"
                        element={<CourseDetails currentUser={currentUser} isAuthenticated={isAuthenticated} />} />
                    <Route path="/k12/:course/:section?/:topic?"
                        element={<K12 />} />
                    <Route path="/my-courses" element={isAuthenticated ?
                        <MyLearning currentUser={currentUser} isAuthenticated={isAuthenticated} /> :
                        <Navigate to="/auth/signin" />} />
                    <Route path="/course/:slug"
                        element={true ? <Learn currentUser={currentUser} isAuthenticated={isAuthenticated} /> :
                            <Navigate to="/auth/signin" />}>
                        <Route path="" element={<Sections />} />
                    </Route>

                    {/* Admin routes */}
                    <Route path="/admin" element={isAuthenticated && parseInt(roleId) === 3 ?
                        <Admin currentUser={currentUser} isAuthenticated={isAuthenticated} /> :
                        <Navigate to="/auth/signin" />}>
                        <Route path="courses" element={<AllCourses currentUser={currentUser} />} />
                        <Route path="authors" element={<Authors currentUser={currentUser} />} />
                        <Route path="categories" element={<Categories currentUser={currentUser} />} />
                    </Route>
                    <Route path="/admin/course/create" element={isAuthenticated && parseInt(roleId) === 3 ?
                        <CreateCourse currentUser={currentUser} isAuthenticated={isAuthenticated} /> :
                        <Navigate to="/auth/signin" />} />
                    <Route path="/admin/chapter/create" element={isAuthenticated && parseInt(roleId) === 3 ?
                        <CreateChapter currentUser={currentUser} isAuthenticated={isAuthenticated} /> :
                        <Navigate to="/auth/signin" />} />
                    <Route path="/admin/author/create" element={isAuthenticated && parseInt(roleId) === 3 ?
                        <CreateAuthor currentUser={currentUser} isAuthenticated={isAuthenticated} /> :
                        <Navigate to="/auth/signin" />} />
                    <Route path="/admin/course/edit/:courseSlug" element={isAuthenticated && parseInt(roleId) === 3 ?
                        <EditCourse currentUser={currentUser} isAuthenticated={isAuthenticated} /> :
                        <Navigate to="/auth/signin" />} />
                    <Route path="/admin/author/edit/:id" element={isAuthenticated && parseInt(roleId) === 3 ?
                        <EditAuthor currentUser={currentUser} isAuthenticated={isAuthenticated} /> :
                        <Navigate to="/auth/signin" />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </>

        )
    }
}

export default App;
