import React, { useEffect, useState } from 'react'
import Header from "../learn/header";
import VideoPlayer from "../learn/videoPlayer";
import PersonImage from "../../assets/images/person.jpg";
import Model from "../../components/globle/model";
import DownloadIcon from "../../assets/icons/downloadIcon";
import Sections from "./sections";
import { useNavigate, useParams } from 'react-router-dom';
import api from "../../services/api";
import { useLocation } from 'react-router-dom';


const K12 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { course, section, topic } = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [loadingContainers, setLoadingContainers] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [resourceModel, setResourceModel] = useState(false)
    const [resources, setResources] = useState([]);
    const [courseResponse, setCourseResponse] = useState(null)
    const [url, setUrl] = useState('')


    const getCourse = async () => {
        try {
            // setIsLoading(true)
            const result = await api.getK12(course);
            setCourseResponse(result.data.data)
            findSection(result.data.data, section, topic);

        } catch (e) {
            alert(e?.response?.data?.message || 'Server Error');
        } finally {
            // setIsLoading(false)
        }
    }

    const getVideoUrl = async (fileId) => {
        try {
            const result = await api.readFileUrl({ fileId: fileId });
            setUrl(result?.data?.data || '')
        } catch (e) {
            console.error(e)
        }
    }

    const findSection = async (course, section_id, topic_id) => {

        if (course && !section_id && !topic_id) {
            const fileId = course?.sections?.[0]?.topics?.[0]?.file_id;
            if (!fileId) return alert('File Id Not Present')
            await getVideoUrl(fileId)
            return;
        }

        if (course && section_id && !topic_id) {
            let sectionI = null;
            for (let i = 0; i < course.sections.length; i++) {
                if (course.sections[i].id === parseInt(section_id)) {
                    sectionI = course.sections[i];
                }
            }
            if (!sectionI) return alert("Invalid Section Id");
            const fileId = sectionI.topics?.[0]?.file_id;
            if (!fileId) return alert('File Id Not Present')
            await getVideoUrl(fileId)
            return;
        }

        if (course && section_id && topic_id) {
            let sectionI = null;

            for (let i = 0; i < course.sections.length; i++) {
                if (course.sections[i].id === parseInt(section_id)) {
                    sectionI = course.sections[i];
                }
            }

            if (!sectionI) return alert("Invalid Section Id");

            let topicI = null

            for (let i = 0; i < sectionI.topics.length; i++) {

                if (sectionI.topics[i].id === parseInt(topic_id)) {
                    topicI = sectionI.topics[i]
                }
            }
            if (!topicI) return alert("Invalid Topic Id")


            const fileId = topicI.file_id;

            if (!fileId) return alert('File Id Not Present')

            await getVideoUrl(fileId)
            return;

        }

    }



    useEffect(() => {

        (async function () {



            if (!course) alert("Invalid Course Name")
            await getCourse();
        })()


    }, [location])

    return (
        <div

            className='bg-black h-100vh overflow-auto scroll-0'>
            <Header name={courseResponse?.name || ""} />
            <div
                className='flex-column-phone p-0-phone '
                style={{
                    display: "flex",
                    padding: "18px"

                }}
            >
                <div
                    className='w-100p-phone p-0-phone overflow-auto scroll-0 h-auto-phone b-radius-0-phone position-sticky-phone top-0-phone'
                    style={{
                        width: "65%",
                        marginRight: "20px",
                        height: "calc(100vh - 20px - 48px - 40px - 3px)",
                        borderRadius: "12px",
                        zIndex: "2"


                    }}>
                    {
                        !isLoading
                            ?
                            <VideoPlayer
                                videoCompleting={0}
                                onNextClick={null}
                                videoUrl={url}
                            />
                            :
                            <div
                                className={"bg-dark-500 b-radius-0-phone"}
                                style={{
                                    width: "100%",
                                    aspectRatio: "639/359",
                                    borderRadius: "12px"
                                }}
                            />
                    }

                    <div
                        className='h-auto-phone b-radius-0-phone p-20-phone overflow-auto bg-dark-500 d-none-phone'
                        style={{
                            width: "100%",
                            backgroundColor: "#F6F6F9",
                            borderRadius: "12px",
                            marginTop: "20px",
                            padding: "40px",
                            overflow: "scroll"

                        }}
                    >
                        {
                            !isLoading
                                ?
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "12px",
                                            marginBottom: "26px"

                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "50%",
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',

                                            }}
                                        >
                                            <img
                                                alt="Avatar"
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    objectFit: "cover"
                                                }}
                                                src={'https://zebralearn-content-studio.s3.ap-south-1.amazonaws.com/images/ZEBRALEARN_WHITE_LOGO.png'} />
                                        </div>
                                        <p
                                            className='fs-14-phone text-white'
                                            style={{
                                                margin: "0",
                                                fontSize: "16px",
                                                fontFamily: "semiBold"
                                            }}
                                        >{"ZebraLearn"}</p>
                                    </div>
                                    <p
                                        className='fs-20-phone text-white'
                                        style={{
                                            margin: "0",
                                            fontSize: "24px",
                                            fontFamily: "semiBold",
                                            marginBottom: "10px"
                                        }}
                                    >Overview</p>
                                    <p
                                        className='fs-14-phone text-gray-100 fs-14'
                                        style={{
                                            margin: "0",
                                            fontSize: "18px",
                                            fontFamily: "medium"
                                        }}
                                    >{"For web-based features, MS Exec for Finance is optimized for the latest versions of popular web browsers such as Google Chrome, Mozilla Firefox, and Microsoft Edge."}</p>
                                </div>
                                :
                                <div
                                    className="description-box-phone"
                                    style={{
                                        width: "100%",
                                        height: "138px"
                                    }}
                                >
                                </div>

                        }


                    </div>
                </div>
                <div
                    className='w-100p-phone mt-20-phone'
                    style={{
                        width: "35%"
                    }}
                >
                    <div
                        className='b-radius-0-phone p-10-phone bg-dark-500 h-auto-phone scroll-0'
                        style={{
                            height: "calc(100vh - 20px - 48px - 40px - 3px)",
                            background: "#F6F6F9",
                            borderRadius: "12px",
                            overflow: "auto",
                            padding: "12px",
                            position: "relative"

                        }}>
                        {
                            isLoading
                                ?
                                <div className='d-flex flex-column g-10 '>
                                    {
                                        loadingContainers.map((i, index) => {
                                            return (
                                                <div

                                                    key={index}
                                                    className={"bg-dark-400 topic-cart-phone"}
                                                    style={{
                                                        width: "100%",
                                                        height: "77px",
                                                        borderRadius: "12px",
                                                    }}
                                                />
                                            )
                                        })

                                    }

                                </div>
                                :

                                <Sections
                                    onTopicClick={(topic) => navigate(`/k12/${course}/${topic.section_id}/${topic.id}`)}
                                    sections={courseResponse?.sections || []}
                                />
                        }
                    </div>
                </div>
            </div>
            <Model
                title={"Resources"}
                subTitle={"Download resources"}
                onCloseClick={() => setResourceModel(false)}
                isShow={resourceModel}
            >
                <div
                    style={{
                        padding: "30px 30px",
                        maxHeight: "500px",
                        overflow: "auto"
                    }}
                >

                    <div
                        className='col-4 col-3-phone'
                        style={{
                            gap: "25px 15px"
                        }}
                    >
                        {
                            resources && resources.length !== 0 ?
                                resources.map((i, index) => {
                                    return (
                                        <div
                                            key={index}
                                        >

                                            <a href={i.link} target={"_blank"}>
                                                <button

                                                    style={{
                                                        borderRadius: "12px",
                                                        height: "140px",
                                                        width: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        overflow: "hidden"
                                                    }} className=" border-0 bg-dark-500 cursor-pointer">
                                                    <div
                                                        className={"bg-dark-500"}
                                                    >
                                                        <DownloadIcon />

                                                    </div>
                                                </button>
                                            </a>

                                            <p
                                                className='text-white'
                                                style={{
                                                    fontSize: "12px",
                                                    fontFamily: "medium",
                                                    color: "black",
                                                    margin: "10px 0 0 0",
                                                    textAlign: "center"
                                                }}
                                            >
                                                Resource {index + 1}
                                            </p>
                                        </div>

                                    )
                                })
                                :
                                <p className='ff-bold text-white'>Loading...</p>
                        }

                    </div>
                </div>
            </Model>
        </div>
    )
}

export default K12
