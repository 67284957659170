import React, { useEffect, useState } from 'react'
import CloseIcon from '../../../assets/icons/closeIcon'
import AddRoundIcon from '../../../assets/icons/addRoundIcon'
import Button from '../../../components/globle/button'
import api from '../../../services/api'
import CrudDropdown from '../../../components/globle/crudDropdown'
import LoadingAnimation from '../../../components/globle/loadingAnimation'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from "../../../components/globle/loading";
import utilities from "../../../utility/utilities";

const UpdateSectionTopic = ({ createdCourseId, resSlug }) => {
    const [res, setRes] = useState()
    const [courseId, setCourseId] = useState(13)
    const [newSection, setNewSection] = useState("")
    const [newTopic, setNewTopic] = useState("")
    const [imageUploadingLoading, setImageUploadingLoading] = useState(false)
    const [topicVideoUrl, setTopicVideoUrl] = useState("")
    const [fileId, setFileId] = useState("")
    const [currentSectionId, setCurrentSectionId] = useState(0)
    const [courseAllDetails, setCourseAllDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [videoUploadingIndex, setVideoUploadingIndex] = useState(0)
    const [currentSectionUpdates, setCurrentSectionUpdates] = useState({
        index: null,
        value: "",
        sectionId: null
    })
    const [currentTopicUpdates, setCurrentTopicUpdates] = useState({
        index: null,
        value: "",
        topicId: null,
        sectionIndex: null
    })
    const [topicLoaddingArray, setTopicLoaddingArray] = useState([])

    const location = useLocation()
    const navigate = useNavigate()


    const getCourseRes = async () => {
        setIsLoading(true)
        const urlPath = location.pathname;
        const urlPathArray = urlPath.split("/")
        const slug = urlPathArray[urlPathArray.length - 1]

        try {
            const courseDetailsRes = await api.getAdminSectionsAndTopicsBySlug(slug)

            if (courseDetailsRes && courseDetailsRes.data && courseDetailsRes.data.data) {
                setRes(courseDetailsRes.data.data)
                setIsLoading(false)
            }
        } catch (e) {
            alert("Unable to fetch course details")
            setIsLoading(false)
        }
        setIsLoading(false)

    }
    const createSectionHandle = async () => {
        try {
            const data = {
                courseId: res.id,
                name: newSection
            }

            const response = await api.createSection(data);
            if (response) {
                getCourseRes()
                setNewSection("")
                setNewTopic("")
            }
        } catch (e) {
            alert(e?.response?.data?.message || "Unable to create section")
        }

    }
    const uploadTopicVideoHandle = async (event, topicId) => {

        try {
            setVideoUploadingIndex(topicId)
            setImageUploadingLoading(true)
            const file = event.target.files[0];

            // create file start
            let fData = {
                name: file.name,
                size: file.size,
                type: file.type,
                duration: 0.00
            }
            // Set up a video element to get the duration
            const video = document.createElement('video');
            video.src = URL.createObjectURL(file);
            video.preload = 'metadata';

            const durationPromise = new Promise((resolve) => {
                video.onloadedmetadata = () => {
                    fData.duration = video.duration;
                    resolve();
                };

            })

            // Clean up the video element
            video.onloadeddata = () => {
                URL.revokeObjectURL(video.src);
            };


            await durationPromise;

            const createFile = await api.fileCreate(fData);

            if (createFile && createFile.data && createFile.data.data && createFile.data.data.id) {
                // setFileId(createFile.data.data.id)
                const data = {
                    fileName: file.name,
                    ContentType: file.type
                }

                const response = await api.getPresignedUrl(data)
                const presignedUrl = response.data.data;

                const fileData = {
                    url: presignedUrl,
                    fileType: file.type,
                    file: file
                }

                const sendDataResponse = await api.sendDataToS3(fileData, (progressEvent) => {
                    const loaded = progressEvent.loaded;
                    const total = progressEvent.total;
                    const percentage = Math.round((loaded * 100) / total);

                    let updatedArray = [...topicLoaddingArray];
                    const index = updatedArray.findIndex(item => item.id === topicId);

                    if (index !== -1) {
                        updatedArray[index].loadingPercentage = percentage;
                    } else {
                        updatedArray.push({
                            id: topicId,
                            loadingPercentage: percentage
                        });
                    }

                    setTopicLoaddingArray(updatedArray);
                });

                if (sendDataResponse) {

                    const imageUrl = presignedUrl.split("?")[0];
                    const videoUrl = imageUrl;
                    const urlObject = new URL(videoUrl);
                    let pathname = urlObject.pathname;
                    let storageKey = "";
                    for (let i = 1; i < pathname.length; i++) {
                        storageKey = storageKey + pathname[i]
                    }
                    const actualNameKey = decodeURIComponent(storageKey);
                    const njernData = {
                        fileId: createFile.data.data.id,
                        storageKey: actualNameKey
                    }
                    const updateKey = await api.updateStorageKey(njernData);
                    setFileId(createFile.data.data.id)

                    // alert(topicId, createFile.data.data.i)

                    const updateVideo = await api.updateTopicVideo(topicId, createFile.data.data.id);
                    getCourseRes()

                }
            }
            // create file end
            setImageUploadingLoading(false)


        } catch (e) {
            alert(e?.response?.data?.message || "Unable to upload video")
        }


    }
    const createTopicHandle = async (sectionId) => {

        try {
            const data = {
                sectionId,
                topicName: newTopic,

            }
            const response = await api.createTopic(data);

            getCourseRes()
            setNewTopic("")
            setTopicVideoUrl("")
            setFileId("")

        } catch (e) {
            alert(e?.response?.data?.message || "Unable to create topic")
        }

    }
    const deleteTopicHandle = async (topicId) => {
        try {
            const userConfirmed = window.confirm("Are you sure you want to proceed?");
            if (userConfirmed) {
                const data = {
                    topicId: topicId
                }
                const response = await api.deleteTopic(data)
                getCourseRes()
            }



        } catch (e) {
            alert("Unable to delete topic")
        }
    }
    const sectionDeleteHandle = async (sectionId) => {
        try {
            const userConfirmed = window.confirm("Are you sure you want to proceed?");
            if (userConfirmed) {
                const data = {
                    sectionId: sectionId
                }
                const response = await api.deleteSection(data);
                getCourseRes();
            }


        } catch (e) {
            alert("Unable to delete")
        }
    }
    const updateSectionHandle = async ({ nativeEvent }) => {
        if (nativeEvent.key === 'Enter') {
            try {
                const response = await api.updateSection(currentSectionUpdates.sectionId, currentSectionUpdates.value);
                await getCourseRes()
                setCurrentSectionUpdates({
                    index: null,
                    value: "",
                    sectionId: null
                })
            } catch (e) {
                alert(e?.response?.data?.message || "Unable to update")
            }
        }


    }
    const updateTopicHandle = async ({ nativeEvent }) => {
        if (nativeEvent.key === 'Enter') {
            try {
                const response = await api.updateTopic(currentTopicUpdates.topicId, currentTopicUpdates.value);
                await getCourseRes()
                setCurrentTopicUpdates({
                    index: null,
                    value: "",
                    topicId: null,
                    sectionIndex: null
                })
            } catch (e) {
                alert(e?.response?.data?.message || "Unable to update")
            }
        }


    }


    useEffect(() => {
    }, [topicLoaddingArray])


    useEffect(() => {
        getCourseRes()
    }, [])


    return (
        <div
            className='top-cont'
            style={{
                marginTop: "72px",
                display: "flex",
                flexDirection: "column",
                gap: "40px"
            }}
        >


            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Upload Course</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "30px"
                    }}
                >
                    {
                        res && res.sections && res.sections.length !== 0 ?
                            res.sections.map((i, index) => {

                                return (
                                    <div
                                        key={index}
                                        style={{
                                            width: "100%"
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px"
                                            }}
                                        >
                                            <input
                                                className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                                                onKeyPress={updateSectionHandle}

                                                onBlur={() => {
                                                    const obj = {
                                                        index: index,
                                                        value: i.name,
                                                        sectionId: i.id
                                                    }
                                                    setCurrentSectionUpdates(obj)
                                                }}

                                                onChange={(event) => {
                                                    const obj = {
                                                        index: index,
                                                        value: event.target.value,
                                                        sectionId: i.id
                                                    }
                                                    setCurrentSectionUpdates(obj)

                                                }}
                                                value={currentSectionUpdates.index === index ? currentSectionUpdates.value : i.name}
                                                placeholder='Section name'
                                                style={{
                                                    background: "#F6F6F9",
                                                    border: "2px solid #F6F6F9",
                                                    borderRadius: "16px",
                                                    height: "65px",
                                                    width: "100%",
                                                    padding: "0 30px",
                                                    fontSize: "16px",

                                                }}
                                            />
                                            <Button

                                                className={'btn-dark-500'}
                                                onClick={() => {
                                                    sectionDeleteHandle(i.id)
                                                }}
                                                title={"delete"} />

                                        </div>

                                        {
                                            i.topics && i.topics.length !== 0 ?

                                                i.topics.map((j, indexj) => {
                                                    return (
                                                        <div
                                                            key={indexj}
                                                            style={{
                                                                flexDirection: "column",
                                                                display: "flex",
                                                                gap: "9px",
                                                                width: "100%",
                                                                marginTop: "19px",
                                                                position: "relative"

                                                            }}
                                                        >

                                                            <div
                                                                className={'border-2-s-dark-400 bg-dark-500'}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    border: "2px solid #F6F6F9",
                                                                    borderRadius: "16px",
                                                                    height: "65px",
                                                                    width: "100%",
                                                                    padding: "0 0 0 30px",

                                                                }}
                                                            >
                                                                <input

                                                                    className={'bg-dark-500 text-white'}
                                                                    onKeyPress={updateTopicHandle}

                                                                    onBlur={() => {
                                                                        const obj = {
                                                                            index: indexj,
                                                                            value: j.name,
                                                                            topicId: j.id,
                                                                            sectionIndex: index
                                                                        }
                                                                        setCurrentTopicUpdates(obj)
                                                                    }}

                                                                    onChange={(event) => {
                                                                        const obj = {
                                                                            index: indexj,
                                                                            value: event.target.value,
                                                                            topicId: j.id,
                                                                            sectionIndex: index
                                                                        }
                                                                        setCurrentTopicUpdates(obj)

                                                                    }}
                                                                    value={currentTopicUpdates.index === indexj && currentTopicUpdates.sectionIndex === index ? currentTopicUpdates.value : j.name}
                                                                    placeholder='Topic name'
                                                                    style={{

                                                                        fontSize: "16px",
                                                                        width: "-webkit-fill-available",
                                                                        border: "0",
                                                                        outline: "none"

                                                                    }}
                                                                />
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        gap: "8px"
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "8px"
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <p title={j?.file?.name || "Add Video"}
                                                                                className='p-0 m-0 fs-12'>{utilities.limitText(j?.file?.name || "", 10)}</p>

                                                                            <input
                                                                                style={{
                                                                                    width: "85px"
                                                                                }}
                                                                                className={`bg-dark-500 border-2-s-dark-400 text-white ${imageUploadingLoading && videoUploadingIndex === j.id ? "d-none" : ""}`}
                                                                                onChange={(e) => uploadTopicVideoHandle(e, j.id)}
                                                                                accept="video/*"
                                                                                type='file' />
                                                                        </div>
                                                                        {
                                                                            imageUploadingLoading && videoUploadingIndex === j.id ?

                                                                                <LoadingAnimation
                                                                                    animateColor={"black"}
                                                                                    backAnimateColor={"white"}
                                                                                    borderWidth={"2px"}
                                                                                    style={{
                                                                                        width: "50px",
                                                                                        height: "50px",
                                                                                    }}
                                                                                />
                                                                                : <></>
                                                                        }

                                                                    </div>

                                                                    <CrudDropdown getCourseRes={getCourseRes}
                                                                        resources={j} />
                                                                    <CloseIcon
                                                                        className={'filter-invert-100'}
                                                                        onClick={() => deleteTopicHandle(j.id)}
                                                                        style={{
                                                                            marginRight: "20px",
                                                                            marginLeft: "20px",
                                                                            cursor: "pointer"
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <AddRoundIcon
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "-47px",
                                                                    top: "14px"
                                                                }}
                                                            /> */}


                                                        </div>
                                                    )
                                                })
                                                :
                                                <></>
                                        }
                                        <div

                                            style={{
                                                flexDirection: "column",
                                                display: "flex",
                                                gap: "9px",
                                                width: "100%",
                                                marginTop: "19px",
                                                position: "relative"

                                            }}
                                        >

                                            <div
                                                className={'border-2-s-dark-400'}
                                                style={{
                                                    alignItems: "center",
                                                    border: "2px solid #F6F6F9",
                                                    borderRadius: "16px",
                                                    height: "65px",
                                                    width: "100%",
                                                    padding: "0 0 0 30px",
                                                    display: currentSectionId === index ? "flex" : "none",
                                                }}
                                            >
                                                <input
                                                    className={'bg-black text-white'}
                                                    value={newTopic}
                                                    onChange={(i) => setNewTopic(i.target.value)}
                                                    placeholder='Topic name'
                                                    style={{

                                                        fontSize: "16px",
                                                        width: "-webkit-fill-available",
                                                        border: "0",
                                                        outline: "none"

                                                    }}
                                                />

                                            </div>


                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                marginTop: "20px"
                                            }}
                                        >
                                            <Button
                                                className={'btn-dark-500'}
                                                onClick={() => createTopicHandle(i.id)}
                                                title={"+ Create Topic"} />
                                            <Button
                                                className={'btn-dark-500'}
                                                onClick={() => {
                                                    setCurrentSectionId(index)
                                                    setTopicVideoUrl("")
                                                }}
                                                title={"Add Topic"} />
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <></>
                    }


                    <div
                        style={{
                            width: "100%"
                        }}
                    >
                        <input
                            className={'bg-black border-2-s-dark-400 text-white outline-none'}
                            value={newSection}
                            onChange={(i) => setNewSection(i.target.value)}
                            placeholder='Section name'
                            style={{
                                border: "2px solid black",
                                borderRadius: "16px",
                                height: "65px",
                                width: "100%",
                                padding: "0 30px",
                                fontSize: "16px",

                            }}
                        />

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                marginTop: "20px"
                            }}
                        >
                            <Button
                                className={'btn-dark-500'}
                                onClick={() => createSectionHandle()}
                                title={"+ Create Section"}
                            />
                        </div>
                    </div>

                </div>
            </div>
            {
                isLoading ?
                    <div
                        className='bg-white b-rounded-circle position-fixed bottom-0 right-0'

                    >
                        <LoadingAnimation
                            className='filter-invert-100'
                        />
                    </div>
                    : <></>
            }

        </div>
    )
}

export default UpdateSectionTopic
