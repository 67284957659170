import React, { useRef, useImperativeHandle, forwardRef } from 'react';

const VideoPlayer = forwardRef(({ nextButton, setNextButton, videoUrl, before10Sec, nextVideoHandle }, ref) => {
    const videoRef = useRef(null);
    const playTimeRef = useRef(0); // Track playtime
    const lastPlayStart = useRef(null); // Track when the video started playing

    // Track playtime when the video plays
    const handlePlay = () => {
        lastPlayStart.current = Date.now();
    };

    // Track playtime when the video pauses or ends
    const handlePauseOrEnd = () => {
        if (lastPlayStart.current) {
            playTimeRef.current += (Date.now() - lastPlayStart.current) / 1000;
            lastPlayStart.current = null;
        }
    };

    // Handle time update for the last 10 seconds
    const handleTimeUpdate = () => {
        const video = videoRef.current;
        if (video && video.duration - video.currentTime <= 10) {
            setNextButton(true);
            before10Sec && before10Sec();
        }
    };

    // Expose method to get playtime and reset it
    useImperativeHandle(ref, () => ({
        getPlayTime: () => {
            if (lastPlayStart.current) {
                playTimeRef.current += (Date.now() - lastPlayStart.current) / 1000;
                lastPlayStart.current = Date.now(); // Reset start time
            }
            const playTime = playTimeRef.current.toFixed(2);
            playTimeRef.current = 0; // Reset playtime
            return playTime;
        },
    }));

    return (
        <div className="position-relative">
            <video
                ref={videoRef}
                onPlay={handlePlay}
                onPause={handlePauseOrEnd}
                onEnded={handlePauseOrEnd}
                controlsList="nodownload"
                className="h-auto-phone b-radius-0-phone bg-dark-500 outline-none"
                autoPlay={true}
                key={videoUrl}
                onTimeUpdate={handleTimeUpdate}
                style={{
                    backgroundColor: "black",
                    borderRadius: "12px",
                    width: "100%",
                    aspectRatio: "639/359",
                }}
                controls
            >
                <source src={videoUrl} type="video/mp4" />
            </video>
            {nextButton && (
                <button
                    onClick={() => {
                        nextVideoHandle();
                        setNextButton(false);
                    }}
                    className="absolute bg-dark-500 text-white bg-dark-400-hover text-hover-white"
                    style={{
                        border: 0,
                        borderRadius: 12,
                        background: "rgb(246, 246, 246)",
                        height: 46,
                        padding: "0px 32px",
                        alignSelf: "flex-end",
                        cursor: "pointer",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        marginBottom: "90px",
                        marginRight: "20px",
                    }}
                >
                    <p style={{ fontFamily: "semiBold", fontSize: 14, margin: 0 }}>Next Video</p>
                </button>
            )}
        </div>
    );
});

export default VideoPlayer;
