// Function to set a cookie
import api from "../services/api";
import config from "../config.json";
import CryptoJS from "crypto-js";
import account from "./appWriteConfig";
import { storeCookieV2 } from "./cookieUtility";

function setCookie(name, value, daysToExpire) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);

    const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;

    document.cookie = cookieString;
}

// Example: Set a cookie named 'accessToken' with a value and expiration of 7 days
//   setCookie('accessToken', 'your_token_value', 7);

// Function to get a cookie value by name
function getCookie(name) {
    const cookies = document.cookie.split('; ');

    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=');
        if (cookieName === name) {
            return cookieValue;
        }
    }

    return null;
}

// Example: Get the value of the 'accessToken' cookie
//   const accessToken = getCookie('accessToken');

const readFileByFileId = async (i) => {
    const data = {
        fileId: i
    }
    const getVideoUrl = await api.readFileUrl(data)
    if (!getVideoUrl) {
        return alert("Something went wrong")
    }
    const url = getVideoUrl?.data?.data || "";
    return url
}

const encodeCryptoWithURIComponent = (plaintext) => {
    const key = config.CRYPTO_KEY;
    const encrypted = CryptoJS.AES.encrypt(plaintext, key).toString();
    const encode = encodeURIComponent(encrypted)
    return encode;
}

const limitText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.slice(0, maxLength) + '...';
    }
};

const uploadImage = async (file) => {
    try {
        let fData = {
            name: file.name,
            size: file.size,
            type: file.type,
        }
        const createFile = await api.fileCreate(fData);

        const data = {
            fileName: file.name,
            ContentType: file.type
        }
        const response = await api.getPresignedUrl(data);

        const presignedUrl = response.data.data;

        const fileData = {
            url: presignedUrl,
            fileType: file.type,
            file: file
        }
        const sendDataResponse = await api.sendDataToS3(fileData);

        const imageUrl = presignedUrl.split("?")[0]
        const urlObject = new URL(imageUrl);
        let pathname = urlObject.pathname;
        let storageKey = "";
        for (let i = 1; i < pathname.length; i++) {
            storageKey = storageKey + pathname[i]
        }
        const actualNameKey = decodeURIComponent(storageKey);

        const updateStorageKeyData = {
            fileId: createFile.data.data.id,
            storageKey: actualNameKey
        }
        const updateKey = await api.updateStorageKey(updateStorageKeyData);

        return createFile.data.data.id
    } catch (e) {
        throw e;
    }
}

const getJwtToken = async () => {

    const token = getCookie("token");

    if (!token) {
        try {
            const user = await account.createJWT();
            const newToken = user.jwt;
            storeCookieV2("token", newToken, 840);
            return newToken
        } catch (e) {
            console.error({ message: "CreateJWT Not Working", error: e })
            // throw e;
        }

    }

    return token


}


export default {
    setCookie,
    getCookie,
    readFileByFileId,
    encodeCryptoWithURIComponent,
    limitText,
    uploadImage,
    getJwtToken
}
